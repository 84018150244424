import '@fancyapps/ui/dist/fancybox.css';
import { Fancybox } from '@fancyapps/ui';

document.addEventListener("DOMContentLoaded", function() {
    Fancybox.bind('[data-fancybox="gallery"]', {
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            'close',
        ],
        afterShow: function(instance, slide) {
            var currentSlideIndex = instance.getSlideIndex(slide);
            var totalSlides = instance.getSlideCount();

            var nextButton = '<button class="gallery-button gallery-next">Next</button>';
            var prevButton = '<button class="gallery-button gallery-prev">Previous</button>';

            var container = document.querySelector('.fancybox-container');
            container.insertAdjacentHTML('beforeend', nextButton + prevButton);

            var nextBtn = container.querySelector('.gallery-next');
            nextBtn.addEventListener('click', function() {
                instance.next();
            });

            var prevBtn = container.querySelector('.gallery-prev');
            prevBtn.addEventListener('click', function() {
                instance.previous();
            });
        },
    });
});