import $ from 'jquery';

window.$ = window.jQuery = $;


$(document).ready(function () {
    /*ШИШКИ*/
    var movementStrength = 25;
    var height = movementStrength / $(window).height();
    var width = movementStrength / $(window).width();
    $(".banner_container").mousemove(function (event) {
        var pageX = event.pageX - ($(window).width() / 2);
        var pageY = event.pageY - ($(window).height() / 2);
        var newvalueX = width * pageX * -1 - 25;
        var newvalueY = height * pageY * -1 - 50;
        $('.hop_cone_1').css('transform', 'translate(' + newvalueX * 0.2 + 'px,' + newvalueY * 0.2 + 'px)');
        $('.hop_cone_3').css('transform', 'translate(' + newvalueX * 0.4 + 'px,' + newvalueY * 0.4 + 'px)');
        $('.hop_cone_4').css('transform', 'translate(' + newvalueX * 0.3 + 'px,' + newvalueY * 0.3 + 'px)');
        $('.hop_cone_5').css('transform', 'translate(' + newvalueX * 0.5 + 'px,' + newvalueY * 0.5 + 'px)');
        $('.hop_cone_6').css('transform', 'translate(' + newvalueX * 0.6 + 'px,' + newvalueY * 0.6 + 'px)');
    });


    /*МЕНЮ*/
    $('.menu_mobile-wrapper').click(function () {
        $('.menu_mobile').toggleClass('open');
        $('.container_top_menu').toggleClass('open_menu_mobile');

        if ($('body').hasClass('no_scroll')) {
            enableScroll();
        } else {
            disableScroll();
        }
    });

    function disableScroll() {
        $('body').addClass('no_scroll');
        $('body').bind('touchmove', function (e) {
            e.preventDefault();
        });
    }

    function enableScroll() {
        $('body').removeClass('no_scroll');
        $('body').unbind('touchmove');
    }


    // Обработчик события клика на кнопку "Очистить форму"
    $(".reset_js").click(function () {
        // Очистка значений всех элементов формы
        $(".brain_filter")[0].reset();
    });

    // Кнопка показать, скрыть фильтр
    $('.show_filters_js').click(function () {
        $('.filters_info').slideToggle(); // Переключение отображения блока с анимацией скольжения

        var buttonText = $(this).text(); // Получаем текущий текст кнопки

        if (buttonText === "Показать фильтр") {
            $(this).text("Скрыть фильтр"); // Если текст кнопки "Показать фильтр", меняем на "Скрыть фильтр"
        } else {
            $(this).text("Показать фильтр"); // Иначе, меняем на "Показать фильтр"
        }
    });

    $('.sidebar_сollapse_js').click(function () {
        $('.left_menu').slideToggle(); // Переключение отображения блока с анимацией скольжения

        var buttonText = $(this).text(); // Получаем текущий текст кнопки

        if (buttonText === "Развернуть меню") {
            $(this).text("Свернуть меню"); // Если текст кнопки "Показать фильтр", меняем на "Скрыть фильтр"
        } else {
            $(this).text("Развернуть меню"); // Иначе, меняем на "Показать фильтр"
        }
    });



    $('.ajax_js_form').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_form').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                    $('.ajax_js_form')[0].reset();
                    $('#successModal').modal('show');
            }
        });
    });

    $('.ajax_js_form_detal').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_form_detal').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                $('.ajax_js_form_detal')[0].reset();
                $('#successModal').modal('show');
                // Выполнить перезагрузку страницы через 2 секунды
                setTimeout(function() {
                    location.reload();
                }, 2000); // 2000 миллисекунд = 2 секунды
            }
        });
    });

    $('.ajax_js_form_modal').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_form_modal').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                $('.ajax_js_form_modal')[0].reset();
                $('#successModal_form').modal('hide');
            }
        });
    });

    $('.ajax_js_form_modal').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_form_modal').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                $('.ajax_js_form_modal')[0].reset();
                $('#successModal_form_contact').modal('hide');
            }
        });
    });

    $('.ajax_js_form_modal_footer').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_form_modal_footer').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                $('.ajax_js_form_modal_footer')[0].reset();
                $('#successModal_footer_contact').modal('hide');
            }
        });
    });

    $(".test_age").click(function(e) {
        e.preventDefault();
        console.log('Был клик');
        let age = $(this).val();
        $('.get_age').val(age);

        // Если старше 18 то закрытть модалку
        if (age === 'Да'){
            $('.modal_bpz_age').modal('hide');

            var form = $(".ajax_js_form_age")[0]; // Получаем объект формы
            var formData = new FormData(form); // Создаем объект FormData и передаем форму

            $.ajax({
                url: $('.ajax_js_form_age').attr('action'), // Получаем URL из атрибута action формы
                type: "POST",
                data: formData,
                processData: false,
                contentType: false,

                success: function(response) {
                    console.log("Всё ок");
                }
            });
        }
    });


    $(".test_age_no").click(function() {
        window.location.href = "https://yandex.ru";
    });

// Не дает закрыть модалку при нажатии не в окне
    $(".modal_bpz_age").modal({
        backdrop: "static",
        keyboard: false
    });

    $('.first_menu_footer').click(function() {
        if ($(window).width() < 720) {
            var lastMenu = $(this).next('.last_menu_footer');
            lastMenu.toggle();
        }
    });

    $('.custom-select_new input').on('click', function() {
        $(this).next('.options-list').slideToggle(200);
    });

    $('.options-list li').on('click', function() {
        var selectedValue = $(this).data('value');
        var inputField = $(this).closest('.custom-select_new').find('input');
        inputField.val(selectedValue);
        $(this).closest('.options-list').slideUp(200);
    });

    // Закрывать выпадающий список при клике вне него
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.custom-select_new').length) {
            $('.options-list').slideUp(200);
        }
    });

});


