import Swiper from 'swiper';


var swiper3 = new Swiper('.swiper-container_header', {
    // Добавляем пагинацию
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});


var slider_2 = new Swiper('.container_adwards_slider', {
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});


var swiper_beer = null, swiper_cider = null, swiper_water = null, swiper_drinks  = null, energy_drinks  = null;

function initSwiper(selector) {
    var swiper = new Swiper(selector, {
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
    });
    return swiper;
}

function destroySwiper(swiper) {
    if (swiper) {
        swiper.destroy();
        swiper = null;
    }
}

function initSwipers() {
    if (window.innerWidth < 768) {
        if ($('.swiper-container_beer').length > 0) {
            if (!swiper_beer) {
                swiper_beer = initSwiper('.swiper-container_beer');
            }
        }
        if ($('.swiper-container_cider').length > 0) {
            if (!swiper_cider) {
                swiper_cider = initSwiper('.swiper-container_cider');
            }
        }
        if ($('.swiper-container_water').length > 0) {
            if (!swiper_water) {
                swiper_water = initSwiper('.swiper-container_water');
            }
        }
        if ($('.swiper-container_non_alcoholic').length > 0) {
            if (!swiper_drinks) {
                swiper_drinks = initSwiper('.swiper-container_non_alcoholic');
            }
        }
        if ($('.swiper-container_energy').length > 0) {
            if (!energy_drinks) {
                energy_drinks = initSwiper('.swiper-container_energy');
            }
        }


    } else {
        if ($('.swiper-container_beer').length > 0) {
            if (swiper_beer) {
                destroySwiper(swiper_beer);
            }
        }
        if ($('.swiper-container_cider').length > 0) {
            if (swiper_cider) {
                destroySwiper(swiper_cider);
            }
        }
        if ($('.swiper-container_water').length > 0) {
            if (swiper_water) {
                destroySwiper(swiper_water);
            }
        }
        if ($('.swiper-container_non_alcoholic').length > 0) {
            if (swiper_drinks) {
                destroySwiper(swiper_drinks);
            }
        }
        if ($('.swiper-container_energy').length > 0) {
            if (energy_drinks) {
                destroySwiper(energy_drinks);
            }
        }
    }
}

function updateSwipers() {
    if (swiper_beer) {
        swiper_beer.update();
    }
    if (swiper_cider) {
        swiper_cider.update();
    }
    if (swiper_water) {
        swiper_water.update();
    }
    if (swiper_drinks) {
        swiper_drinks.update();
    }
    if (energy_drinks) {
        energy_drinks.update();
    }
}

function checkScreenWidth() {
    initSwipers();
}


// Проверка на слайдер с табуляцией на главной
if ($('.container_shop_tab').length > 0) {
    //Если есть то
    window.addEventListener('resize', checkScreenWidth);
    window.addEventListener('DOMContentLoaded', checkScreenWidth);
    console.log("Элемент с селектором '.container_shop_tab' присутствует на странице.");
} else {
    console.log("Элемент с селектором '.container_shop_tab' отсутствует на странице.");
}


$(document).ready(function () {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var activeTab = e.target.getAttribute('href');
        console.log("Открыта вкладка " + activeTab);
        updateSwipers();
    });
});


/*СЛАЙДЕР ДЕТАЛЬНОЙ*/


var detailSlider = new Swiper('.detail_slider', {
    direction: 'vertical',
    navigation: {
        nextEl: '.detail_slider_button_next',
        prevEl: '.detail_slider_button_prev',
    },
    breakpoints: {
        // Ширина экрана <1200px
        768: {
            direction: 'vertical',
        },
        200: {
            slidesPerView: 1,
            direction: 'horizontal',
        },
    },
});

var thumbsSlider = new Swiper('.thumbs', {
    direction: 'vertical',
    slidesPerView: 3,
    spaceBetween: 5,
    centeredSlides: true,
    slideToClickedSlide: true,
});


if (detailSlider.initialized && thumbsSlider.initialized) {
    console.log("Слайдеры успешно инициализированы на странице.");

    detailSlider.controller.control = thumbsSlider;
    thumbsSlider.controller.control = detailSlider;
} else {
    console.log("Ошибка инициализации слайдеров.");
}


var swiper_brand = new Swiper('.brand_slider_container', {
    slidesPerView: 6, // Количество отображаемых слайдов
    spaceBetween: 10, // Расстояние между слайдами
    navigation: {
        nextEl: '.swiper-button-next', // Кнопка "следующий слайд"
        prevEl: '.swiper-button-prev', // Кнопка "предыдущий слайд"
    },
    loop: true,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
    breakpoints: {
        // Ширина экрана <1200px.btn__close
        1200: {
            slidesPerView: 5, // Изменяем количество отображаемых слайдов на 5
        },
        // Ширина экрана <992px
        992: {
            slidesPerView: 4, // Изменяем количество отображаемых слайдов на 4
        },
        // Ширина экрана <768px
        768: {
            slidesPerView: 3, // Изменяем количество отображаемых слайдов на 3
        },
        290: {
            slidesPerView: 2, // Изменяем количество отображаемых слайдов на 2
        },
    },
});



var swiper_equipment = new Swiper('.swiper_equipment', {
    // Добавляем пагинацию
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});


var container_job_slider = new Swiper('.container_job_slider', {
    spaceBetween: 20,
    slidesPerView: 4,
    breakpoints: {
        // Ширина экрана >=1200px
        1200: {
            slidesPerView: 4,
        },
        // Ширина экрана <1200px и >=992px
        992: {
            slidesPerView: 3,
        },
        // Ширина экрана <992px и >=768px
        768: {
            slidesPerView: 2,
        },
        // Ширина экрана <768px и >=290px
        290: {
            slidesPerView: 1,
        },
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});

