import './include/jquery';

import './include/sliders';

import 'bootstrap/dist/js/bootstrap.js'; // импортирует все компоненты

import './include/init-maskedinput';

import './include/fancybox';

