import Inputmask from "maskedinput";

document.addEventListener('DOMContentLoaded', () => {
    let russsianPhone = document.querySelectorAll(".js-mask-ru");

    if (russsianPhone) {
        russsianPhone.forEach(el => {
            let im = new Inputmask("9 999 999 99 99", {placeholder: "_ ___ ___ __ __"});
            im.mask(el);
        });
    }


    let date_format = document.querySelectorAll(".date_format");
    if (date_format) {
        date_format.forEach(el => {
            let im = new Inputmask("99/99/9999");
            im.mask(el);
        });
    }
});